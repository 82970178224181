import React from 'react';
import FAQ from '../../FAQ';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';

function KeywordsTrackerFAQ() {
  const { t } = useTranslation('KeywordTracker');
  const items = [
    {
      question: t('KeywordsTrackerFAQ.item1.question'),
      answer: t('KeywordsTrackerFAQ.item1.answer'),
    },
    {
      question: t('KeywordsTrackerFAQ.item2.question'),
      answer: t('KeywordsTrackerFAQ.item2.answer'),
    },
    {
      question: t('KeywordsTrackerFAQ.item3.question'),
      answer: t('KeywordsTrackerFAQ.item3.answer'),
    },
    {
      question: t('KeywordsTrackerFAQ.item4.question'),
      answer: t('KeywordsTrackerFAQ.item4.answer'),
    },
    {
      question: t('KeywordsTrackerFAQ.item5.question'),
      answer: t('KeywordsTrackerFAQ.item5.answer'),
    },
  ];

  return (
    <ContentSection background={2}>
      <FAQ items={items} />
    </ContentSection>
  );
}

export default KeywordsTrackerFAQ;
