import React from 'react';
import Pricing from '../components/Pricing';
import Features2 from '../components/pages/KeywordTracker/Features2';
import MetaTags from '../components/Seo/MetaTags';
import { useTranslation } from 'react-i18next';
import HeroKeywordTracker from '../components/pages/KeywordTracker/HeroKeywordTracker';
import Features from '../components/pages/KeywordTracker/Features';
import GroupingAndClustering from '../components/pages/KeywordTracker/GroupingAndClustering';
import CompetitorAnalysis from '../components/pages/KeywordTracker/CompetitorAnalysis';
import TrackingForAllBudgets from '../components/pages/KeywordTracker/TrackingForAllBudgets';
import KeywordsTrackerFAQ from '../components/pages/KeywordTracker/KeywordsTrackerFAQ';
import Brands from '../components/Brands';
import StructuredService from '../components/Seo/StructuredService';
import StructuredAggregateRating from '../components/Seo/StructuredAggregateRating';

const KeywordTrackerPage = () => {
  const { t } = useTranslation('KeywordTracker');

  return (
    <>
      <MetaTags title={t('seo.title')} description={t('seo.description')} />
      <StructuredService name="keyword tracker" />
      <StructuredAggregateRating
        name="SpySERP keyword tracker tool"
        description="Choose the plan that fits your needs and budget best. Calculate how many keyword checks you need, choose your subscription plan, and get full access to a wide array of SERP tools and features."
        makesOffer="keyword tracker"
        knowsAbout="keyword tracker tool"
        ratingValue={5}
        bestRating={5}
        ratingCount={24}
      />

      <HeroKeywordTracker />
      <Features />
      <GroupingAndClustering />
      <CompetitorAnalysis />
      <TrackingForAllBudgets />
      <Pricing />
      <Features2 />
      <Brands />
      <KeywordsTrackerFAQ />
    </>
  );
};

export default KeywordTrackerPage;
