import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContentSection from '../../ContentSection';
import LayerGroupIcon from '../../../assets/icons/font-awesome/solid/layer-group.svg';
import CommentsIcon from '../../../assets/icons/font-awesome/solid/comments.svg';
import ChartPieIcon from '../../../assets/icons/font-awesome/solid/chart-pie.svg';
import UserIcon from '../../../assets/icons/font-awesome/solid/user.svg';

const Features2 = () => {
  const { t } = useTranslation('KeywordTracker');
  const title = t('Features2.title');
  const features = [
    {
      label: t('Features2.feature1.title'),
      text: t('Features2.feature1.text'),
      icon: LayerGroupIcon,
      position: 'left',
      aosDelay: 200,
      bg: 'red',
      id: 1,
    },
    {
      label: t('Features2.feature2.title'),
      text: t('Features2.feature2.text'),
      icon: CommentsIcon,
      position: 'left',
      aosDelay: 400,
      bg: 'success',
      id: 2,
    },
    {
      label: t('Features2.feature3.title'),
      text: t('Features2.feature3.text'),
      icon: ChartPieIcon,
      position: 'left',
      aosDelay: 200,
      bg: 'black',
      id: 3,
    },
    {
      label: t('Features2.feature4.title'),
      text: t('Features2.feature4.text'),
      icon: UserIcon,
      position: 'left',
      aosDelay: 200,
      bg: 'blue',
      id: 4,
    },
  ];

  return (
    <ContentSection title={title}>
      <Row className="justify-content-center">
        <Col md="11">
          <Row className="align-items-start justify-content-center">
            {features.map((feature) => {
              const { label, text, position, aosDelay, bg, id } = feature;

              return (
                <Col
                  key={id}
                  lg="6"
                  md="10"
                  className="mb-11"
                  data-aos={`fade-${position}`}
                  data-aos-duration="800"
                  data-aos-delay={aosDelay}
                >
                  <div className="feature-widget media">
                    <div
                      className={`widget-icon p-7 rounded-15 mr-9 gr-bg-${bg}-opacity-1 gr-color-${bg}-opacity-8`}
                    >
                      <span className={`svg-color-${feature.bg}`}>
                        <feature.icon height={32} />
                      </span>
                    </div>

                    <div className="widget-text">
                      <h3 className="title gr-text-7 mb-6">{label}</h3>
                      <p className="gr-text-9 mb-0 pr-11">{text}</p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </ContentSection>
  );
};

export default Features2;
