import Hero from '../../Hero';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

function HeroKeywordTracker() {
  const { t } = useTranslation('KeywordTracker');
  const title = t('HeroKeywordTracker.title');
  const description = t('HeroKeywordTracker.description');

  return (
    <Hero title={title} description={description}>
      <div
        className="mt-10 px-md-10"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
        <StaticImage
          src="../../../assets/image/keyword-tracker/keyword1_web.png"
          width={1040}
          sizes="(min-width: 1040px) 1040px, (min-width: 360px) 330px, 100vw"
          breakpoints={[330, 635, 990, 1040, 1915]}
          loading="eager"
          placeholder="blurred"
          alt={title}
          className="shadow-1"
          itemProp="contentUrl"
        />
      </div>
    </Hero>
  );
}

export default HeroKeywordTracker;
